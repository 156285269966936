import React from 'react'
import Layout from '../components/layout'
import SEO from '../components/seo'
import { Container , Row , Col } from 'reactstrap'
import { graphql } from "gatsby"
import Img from "gatsby-image"



    export default class AboutPage extends React.Component {
      render() {

        const data = this.props.data

            //control
            const efdal = data.efdal.childImageSharp.fluid
            const pagebgkurumsal = data.pagebgkurumsal.childImageSharp.fluid

            return (

  <Layout pageTitle="Kurumsal">
  <SEO title="Kurumsal Web Tasarım Ajansı" keywords={[`istanbul Web Tasarım Firma`, `Kurumsal Web Sitesi`, `Kurumsal Web Tasarım`]} description="İstanbul Web Tasarım 2012 den bu yana sizlere kurumsal Web Tasarım hizmeti sunuyor" />

    <section className="bginpage">
    <Img fluid={pagebgkurumsal}
    style={{
                   position: 'absolute',
                   left: 0,
                   top: 0,
                   width: '100%',
                   height: '100%',
                 }} alt="Kurumsal Web Tasarım"/>
    <Container>
      <Row>
        <Col md="5">
        <div className="bginpagedark">
        <h1 className="mb20  section-title">
        Kurumsal
             </h1>
             <p>Bizler kaliteli ve kalıcı yazılım fikri ile Buradaydık! Fikirlerimiz ve kurumsal çalışma prensibimiz ile bugünün değil, yarının teknolojileri ile ihtiyaçlarınıza yönelik bulut tabanlı yazılım çözümleri sunuyoruz.</p>
      </div>  </Col>
        </Row>
        </Container>
  </section>
    <section className="maincontent">
  <Container>
  <Row>
  <Col md="12" className="text-center">
  <h1 className="font-size-normal  section-title">
           <small>Bir Web Tasarım ajansından Çok Daha Fazlası !</small>
           İstanbul Web Tasarım
            <div className="bar"></div>
       </h1>
       <p>Fikirleriniz değerlidir ! İstanbul Web Tasarım beklentinizin ötesinde yenilikçi, dinamik ve alternatif çözümler sunar !</p>
</Col>
</Row>
    <Row>
    <Col md="12 mb20">
    <div className="acik boxerpad">
    <p>
    İstanbul Web Tasarım 2012 den bu yana müşterileri ile potansiyel müşterileri arasında etkileşim gücünü arttırmaya yönelik projeler üretebilen ,sürekli olarak yeni iş fikirleri ile dinamizminizi canlandıran bir web tasarım ajansıdır.
Yerli ve yabancı sektörlerde yeni güçlü imajlar ve markalar meydana getirerek üretkenliğinizi artırmanın yanısıra kalıcılığı yakalamak temel hedefimizdir.Dolayısıyla sonuç odaklı geliştirdiğimiz projelerin başarılarının kaçınılmaz olması, bizleri bir web tasarım ajansı olmanın her zaman bir adım önüne taşımaktadır.Sürekli olarak sizlerden aldığımız ilham ve destekle ekibimizin ve çalışma alanımızın büyümeside bunun doğal bir sonucudur.
İstanbul Web Tasarım müşterilerinin talepleri doğrultusunda Web Tasarım ve programlama , e-ticaret , arama motoru optimizasyonu , google reklamcılık , kurumsal kimlik ,alan adı tescili ve alan adı barındırma yönünde faaliyetlerini sürdürmektedir.
İstanbul Web Tasarım çeşitli faktörleri sektörde dikkatlice analiz etmiş ve sizler için kendini en iyi şekilde tanıtabilmek adına çeşitli değerlerini ön plana çıkartmıştır.
    </p>
    </div>
    </Col>
    </Row>
    <Row>
    <Col md="6" lg="6">
    <div className="single-features">
      <div className="icon">
      <i className="icon-3x fa fa-desktop"></i>
      </div>

      <h3 className="font-size-normal">Müşteri memnuniyeti</h3>
      <p>Müşteri ihtiyaçlarına bağlı olarak çeşitli çözümler ve öneriler sunarız, müşterilerinin koşulsuz memnuniyeti için her türlü çabayı göstermeyi taahhüt eden bir hizmet anlayışı ile hizmet vermekteyiz.</p>
    </div>
    </Col>
    <Col md="6" lg="6">
    <div className="single-features">
      <div className="icon">
      <i className="icon-3x fa fa-desktop"></i>
      </div>

      <h3 className="font-size-normal">Fiyat Anlayışı</h3>
      <p>Hizmet verdiği kuruluşların farklı ihtiyaçlarına yönelik standart fiyat tekliflerimiz bulunmaktadır.Hiç kimsenin firmasının boyutu fiyatı etken olmadığı gibi temel amacımız büyük firma ile çalışıp çok kazanmak değil firmanız için ne yapabilir de büyütebiliriz şeklindedir dolayısıyla standart ve şeffaf fiyat politikamız gereği her müşteriye aynı fiyat yaklaşımıyla hizmet vermekteyiz..</p>
    </div>
    </Col>
    </Row>
    <Row>
    <Col md="6" lg="6">
    <div className="single-features">
      <div className="icon">
      <i className="icon-3x fa fa-desktop"></i>
      </div>

      <h3 className="font-size-normal">Profesyonel Yaklaşım</h3>
      <p>Bizim için öncelikli olan sizi değil, hedef kitlenizi etkilemektir.İşimizin büyümesinin, sizin işinizin büyümesiyle orantılı olduğunun her zaman bilincindeyiz.</p>
    </div>
    </Col>
    <Col md="6" lg="6">
    <div className="single-features">
      <div className="icon">
      <i className="icon-3x fa fa-desktop"></i>
      </div>

      <h3 className="font-size-normal">Tasarım Anlayışı</h3>
      <p>İşlevsel olanı estetikle bütünleştirerek kullanıcı deneyiminin ön planda tutulduğu Web Tasarım projeleri İstanbul Web Tasarımın tutkusudur..</p>
    </div>
    </Col>
    </Row>
    <Row>
    <Col md="6" lg="6">
    <div className="single-features">
      <div className="icon">
      <i className="icon-3x fa fa-desktop"></i>
      </div>

      <h3 className="font-size-normal">Oluşumcu</h3>
      <p>Marka,teknoloji,tasarım,network ve bunun gibi kalıcı ve sıradışı üstünlükler oluşturmak ve İstanbul Web Tasarım ‘ın farkını yansıtmak oluşumcu kimliğimizin göstergesidir.</p>
    </div>
    </Col>
    <Col md="6" lg="6">
    <div className="single-features">
      <div className="icon">
      <i className="icon-3x fa fa-desktop"></i>
      </div>

      <h3 className="font-size-normal">Sorumlu ve Şeffaf</h3>
      <p>Öz değerlerimize bağlı,inançlara saygılı,müşterilerimize yakın,sosyal sorumluluk bilincine sahip bir firmayız.</p>
    </div>
    </Col>
    </Row>
    <Row>
    <Col md="6" lg="6">
    <div className="single-features">
      <div className="icon">
      <i className="icon-3x fa fa-desktop"></i>
      </div>

      <h3 className="font-size-normal">Aktif Katılım</h3>
      <p>Müşterilerimize işlerinin tüm süreçlerinde katılım ve dinamik bir birliktelik sağlıyoruz.</p>
    </div>
    </Col>


    <Col md="6" lg="6">
    <div className="single-features">
      <div className="icon">
      <i className="icon-3x fa fa-desktop"></i>
      </div>

      <h3 className="font-size-normal">Stratejik Yaklaşım</h3>
      <p>Bugünkü işlerimizi mükemmel yönetmek ve uzun dönemde kalıcı başarılar elde etmek hedeflerimizdendir.</p>
    </div>
    </Col>

    </Row>
    <Row>
      <Col md="7">
      <div className="boxer boxerpad">
       <Img fluid={efdal} alt="Yusuf Alper" className="" />
       </div>
      </Col>
      <Col md="5">
  <div className="boxer boxerpad">
  <p>His yok , hareket yok , aci yok ...<br />
Les mi kesildin ?<br />
Hayret veriyorsun bana...<br />
Sen boyle degildin.<br />
Feryadi birak kendine gel ,<br />
Cunku zaman dar ...<br />
Ugras ki ,<br />
Telafi edilicek bunca zarar var...<br /> ( M.Akif Ersoy )
<br />
Ülkemizi yüksek teknoloji yazılımlarla buluşturmak bizim sevdamız...<br />
İstanbulWebTasarım Ekibi

</p>
  </div>
      </Col>
    </Row>
    </Container>
    </section>
  </Layout>
)

}
}

export const pageQuery = graphql`
query {
efdal: file(
 relativePath: { regex: "/efdal.jpg/" }
) {
childImageSharp {
           fluid{
             ...GatsbyImageSharpFluid_withWebp
           }
         }
}
pagebgkurumsal: file(
 relativePath: { regex: "/bg16.jpg/" }
) {
childImageSharp {
          fluid(quality: 100){
             ...GatsbyImageSharpFluid_withWebp
           }
         }
}
}
`
